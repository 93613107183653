import { create } from 'zustand'
import { persist } from 'zustand/middleware';

export const AnonymousNameMapStore = create(persist(
    (set, get) => ({
        // if study id not inside map then send without PHI will use this instead
        // only applicaabale for batch transfering
        anonymousNameMap: new Map(),
        getAnonymousNameMap: (key) => {
            const { anonymousNameMap } = get();
            return anonymousNameMap.get(key);
        },
        setAnonymousNameMap: (key, value) => set((state) => {
            const newMap = new Map(state.anonymousNameMap || new Map());
            newMap.set(key, value);
            return { anonymousNameMap: newMap };
        }),
    }),
    {
        name: 'AnonymousNameMapStore',
        getStorage: () => sessionStorage,
    })
);
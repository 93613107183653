import { Layout, Divider, message } from "antd";
import { SourceGatewayDicomNodeSelection } from "../components/GatewayDicom/SourceGatewayFilter";
import { DestinationGateway } from "../components/GatewayDicom/DestinationGatewayFilter";
import { ExcelList } from "./ExcelList";
import { ImageTransferingStore } from '../../stores/gateway';
import { BatchTransferingDicomNodeStore } from '../../stores/dicom-node';
import { BatchTransferingStudiesKeystore } from "../../stores/rowsKey";
import { BatchTransferingImageStudiesStore, BatchTransferingExcelListStore } from '../../stores/studies';
import { ExcelRawDataStore } from '../../stores/excel';
import {
  listAllGatewayByEmail,
  listAllGatewayByUserID,
} from "../../apis/gateway";
import {
  CLOUD_PACS_MODE,
  GATEWAY_ONLINE_STATUS,
} from "../constants/dicomNode";
import _ from "lodash";
import { fetchGatewaysStatus } from '../components/GatewayDicom/hooks/GatewayStatusCustomHook';
import { fetchDicomNodes } from '../components/GatewayDicom/hooks/DicomNodeCustomHook';
import { BatchTransferingJobStore } from '../../stores/jobs'
import { useState, useEffect } from 'react'

const CloudPacs = {
  id: CLOUD_PACS_MODE,
  name: "Cloud PACS System",
  status: GATEWAY_ONLINE_STATUS,
  listener_ip: "N/A",
  listener_status: "N/A",
  gateway_ip: "N/A",
};

export const BatchTransfering = () => {
  const clearAllCBKeys = BatchTransferingStudiesKeystore((state) => state.clearAllCBKeys)
  const clearExpandedRowKeys = BatchTransferingStudiesKeystore((state) => state.clearExpandedRowKeys)
  const [isLoadingGateway, setLoadingGateway] = useState(false);
  const [isGatewayDestinationSearching] = useState(false);
  const [isLoadingDicomNode, setLoadingDicomNode] = useState(false);
  const gatewayData = ImageTransferingStore((state) => state.gatewayData)
  const setGatewayData = ImageTransferingStore((state) => state.setGatewayData)
  const setDestinationGateways = ImageTransferingStore((state) => state.setDestinationGateways)
  const destinationGateways = ImageTransferingStore((state) => state.destinationGateways)
  const sourceDicomeNodeID = BatchTransferingDicomNodeStore((state) => state.sourceDicomeNodeID)
  const setSourceDicomeNodeID = BatchTransferingDicomNodeStore((state) => state.setSourceDicomeNodeID)
  const setSourceGatewayId = ImageTransferingStore((state) => state.setSourceGatewayId)
  const selectedDestionationSourceGateway = ImageTransferingStore((state) => state.selectedDestinationGateway)
  const setDestionationSelectedSourceGateway = ImageTransferingStore((state) => state.setSelectedDestinationGateway)
  const setDestinationDicomNodes = BatchTransferingDicomNodeStore((state) => state.setDestinationDicomNodes)
  const destinationDicomNodes = BatchTransferingDicomNodeStore((state) => state.destinationDicomNodes)
  const currentSelectedListStudies = BatchTransferingImageStudiesStore((state) => state.currentSelectedSeries)
  const setStudyData = BatchTransferingImageStudiesStore((state) => state.setStudyData)
  const setSelectedListStudies = BatchTransferingImageStudiesStore((state) => state.setSelectedSeries)
  const setStudyWithSeries = BatchTransferingExcelListStore((state) => state.setStudyWithSeriesData)
  const setExcelRawData = ExcelRawDataStore((state) => state.setExcelRawData)
  const excelRawData = ExcelRawDataStore((state) => state.excelRawData)
  const setExcelTableData = ExcelRawDataStore((state) => state.setExcelTableData)
  const clearAllStudyData = BatchTransferingImageStudiesStore((state) => state.clearAllStudyData)

  const refetchCurrentGatewayStatus = async (gateways) => {
    try {
      if (!gateways || gateways.length === 0) return;
      const gatewayWithStatus = await fetchGatewaysStatus(gateways);
      return gatewayWithStatus;
    } catch (e) {
      console.error(e);
      return [];
    }
  }


  const onExcelFileReadFinish = (excelFileData) => {
    // clear all excel raw data and table
    setExcelRawData([]);
    setExcelTableData([]);
    // clear all options
    clearAllCBKeys();
    clearExpandedRowKeys();
    clearAllStudyData();
    
    // clear study data and series
    setStudyData({})
    setSelectedListStudies([])
    setStudyWithSeries({});
    // set new raw data table
    setExcelRawData(excelFileData);
  };

  const fetchGateway = async () => {
    try {
      setLoadingGateway(true);
      const response = await listAllGatewayByUserID();
      if (!response.data) return;

      const gatewayWithStatus = await fetchGatewaysStatus(response.data);
      return gatewayWithStatus;
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingGateway(false);
    }
  };

  const fetchUserSourceGateway = async () => {
    try {
      const gatewayWithStatus = await fetchGateway();
      setGatewayData(gatewayWithStatus);
      setSourceGatewayId(gatewayWithStatus[0].id);
    } catch(e) {
      console.error(e);
    }
  }

  const fetchUserDestinationGateway = async () => {
    try {
      const gatewayWithStatus = await fetchGateway();
      setDestinationGateways(gatewayWithStatus);
      setDestionationSelectedSourceGateway(CloudPacs);
    } catch(e) {
      console.error(e);
    }
  }

  useEffect(() => {
    if (!gatewayData || gatewayData.length === 0) {
      fetchUserSourceGateway();
      fetchUserDestinationGateway();      
    } else {
      setGatewayData(gatewayData);
    }
  }, [])
  const selectedSourceGatewayId = ImageTransferingStore((state) => state.sourceGatewayId)
  const setSelectedDestinationDicomeNode = BatchTransferingDicomNodeStore((state) => state.setDestinationDicome)
  const currentSelectedGateway = gatewayData && gatewayData.find((gateway) => gateway.id === selectedSourceGatewayId);
  const storedDicomNodes = BatchTransferingDicomNodeStore((state) => state.sourceDicomNodes)
  const setDicomNodes = BatchTransferingDicomNodeStore((state) => state.setSourceDicomNodes)

  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
    const fetchDicomNode = async () => {
      if (isLoadingDicomNode) return;
      if (!currentSelectedGateway || !currentSelectedGateway.id) return;
      try {
        setLoadingDicomNode(true)
        const data = await fetchDicomNodes({ currentSelectedGateway });
        setDicomNodes(data);
      } catch (e) {
        messageApi.open({
          key: `gateway-dicome-listing-${currentSelectedGateway.id}`,
          type: 'error',
          content: e.message || 'failed to fetch dicom node',
        });
      } finally {
        setLoadingDicomNode(false);
      }
    }

    if (!storedDicomNodes || storedDicomNodes.length === 0) {
      fetchDicomNode();
    }
  }, [currentSelectedGateway])


  // Fetch dicom for destination gateway
  const currentSelectedDestinationGateway = destinationGateways && destinationGateways.find((gateway) => gateway.id === selectedDestionationSourceGateway.id);
  useEffect(() => {
    const fetchDicomNode = async () => {
      if (!currentSelectedDestinationGateway) return;
      try {
        const data = await fetchDicomNodes({ currentSelectedGateway: currentSelectedDestinationGateway });
        setDestinationDicomNodes(data);
      } catch (e) {
        messageApi.open({
          key: `gateway-dicome-listing-${currentSelectedGateway.id}`,
          type: 'error',
          content: e.message || 'failed to fetch dicom node',
        });
      }
    }

    if (!destinationDicomNodes || destinationDicomNodes.length === 0) {
      fetchDicomNode();
    }
  }, [selectedDestionationSourceGateway])

  const onSourceGatewaySelect = async (gatewayId) => {
    if (gatewayId != selectedSourceGatewayId) {
      // clear the dicom node if different
      setDicomNodes([]);
    }

    setSourceGatewayId(gatewayId);
    const result = await refetchCurrentGatewayStatus(gatewayData)
    setGatewayData(result);
  };

  const onDicomNodeSelected = (nodeId) => {
    setSourceDicomeNodeID(nodeId);
  };

  const selectedDestinationDicomNodeId = BatchTransferingDicomNodeStore((state) => state.destinationDicome)
  const onDestinationGatewayChange = (gateway) => {
    if (gateway != selectedDestionationSourceGateway) {
      // gateway select is different clear;
      setDestinationDicomNodes([]);
    }
    setDestionationSelectedSourceGateway(gateway)
  }

  const onDestinationDicomNodeChange = (node) => {
    setSelectedDestinationDicomeNode(node);
  }

  const addJob = BatchTransferingJobStore((state) => state.addJob)
  const updateRunningJobs = BatchTransferingJobStore((state) => state.updateRunningJobs)
  const runningJobs = BatchTransferingJobStore((state) => state.runningJobs)

  const onSearchForSourceGateWay = async (gateways) => {
    setGatewayData([]);
    setDicomNodes(null);

    if (gateways && gateways.length > 0) {
      const result = await refetchCurrentGatewayStatus(gateways);
      setGatewayData(result);
    } else {
      fetchUserSourceGateway();
    }
  }

  const onSearchForDestinationGateWay = async (gateways) => {
    setDestinationGateways([]);
    setSelectedDestinationDicomeNode(null);
    setDestinationDicomNodes([]);
    setDestionationSelectedSourceGateway(CloudPacs)
    //
    if (gateways && gateways.length > 0) {
      const result = await refetchCurrentGatewayStatus(gateways);
      setDestinationGateways(result);
    } else {
      fetchUserDestinationGateway();
    }
  }

  return (
    <Layout className="bg-white flex-row flex-col w-full">
      {contextHolder}
      <div className="p-4 max-w-screen-2xl mx-0 center mx-auto">
        <h1 className="text-2xl font-bold text-center mb-4">Batch Transfering</h1>
        <Divider />
        <SourceGatewayDicomNodeSelection
          onExcelFileReadFinish={onExcelFileReadFinish}
          onGatewaySelect={onSourceGatewaySelect}
          onDicomNodeSelected={onDicomNodeSelected}
          isLoadingGateway={isLoadingGateway}
          isLoadingDicomNode={isLoadingDicomNode}
          gatewayData={gatewayData}
          dicomNode={storedDicomNodes}
          sourceDicomeNodeID={sourceDicomeNodeID}
          currentSelectedGatewayId={selectedSourceGatewayId}
          currentSelectedGateway={currentSelectedGateway}
          onSearchGatewayResultsHandle={onSearchForSourceGateWay}
          isEnableExcelUpload={true}
        />
        <Divider />
        <ExcelList
          sourceDicomeNodeID={sourceDicomeNodeID}
          sourceGatewayID={selectedSourceGatewayId}
          excelRawData={excelRawData}
        />
        <Divider />
        <DestinationGateway
          onGatewaySelect={onDestinationGatewayChange}
          onDicomSelect={onDestinationDicomNodeChange}
          isLoadingGateway={isLoadingGateway}
          isGatewayDestinationSearching={isGatewayDestinationSearching}
          selectedSourceGatewayId={selectedSourceGatewayId}
          selectedSourceDicomNodeId={sourceDicomeNodeID}
          selectedDestionationGateway={selectedDestionationSourceGateway}
          selectedDestinationDicomNodeId={selectedDestinationDicomNodeId}
          selectedStudies={currentSelectedListStudies}
          dicomNode={destinationDicomNodes}
          runningJobs={runningJobs}
          addJob={addJob}
          onSearchGatewayResultsHandle={onSearchForDestinationGateWay}
          updateRunningJobs={updateRunningJobs}
          gatewayData={[CloudPacs, ...destinationGateways]}
        />
      </div>
    </Layout>
  );
};

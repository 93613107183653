import { useContext } from "react";
import { ExcelReader } from "../ExcelReader";
import { GatewaySelectionTable } from "../../components/Gateway/Gateway";
import { DicomNodeSelectionTable } from "../../components/DicomNode/DicomNodeSelection";
import { AppContext } from '../../../libs/context';
import { MODAL_MANGE_DICOM_NODE } from '../../constants/modals';

import _ from "lodash";

export const SourceGatewayDicomNodeSelection = ({
  onExcelFileReadFinish,
  onDicomNodeSelected,
  onGatewaySelect,
  isEnableExcelUpload,
  currentSelectedGatewayId,
  currentSelectedGateway,
  sourceDicomeNodeID,
  gatewayData,
  dicomNode,
  isLoadingGateway,
  isLoadingDicomNode,
  onSearchGatewayResultsHandle
}) => {
  const { _, setCurrentModal } = useContext(AppContext);
  const onGatewayChange = (selectedGateway) => onGatewaySelect(selectedGateway.id || null);
  const onManageNodeCallBack = () => {
    setCurrentModal({
      modal: MODAL_MANGE_DICOM_NODE,
      data: {
        gateway_id: currentSelectedGatewayId,
        dicomNode
      },
    })
  };

  const onDicomSelectCallBack = (selectedDicomNodeId) => {
    if (selectedDicomNodeId) {
      onDicomNodeSelected(selectedDicomNodeId);
    }
  };

  return (
    <div className="w-full flex">
      <GatewaySelectionTable
        showKillJob={true}
        allowShowEditGateway={true}
        onGatewayChange={onGatewayChange}
        isLoading={isLoadingGateway}
        gatewayData={gatewayData}
        selectedGateway={currentSelectedGateway}
        onSearchGatewayResultsHandle={onSearchGatewayResultsHandle}
      />
      <DicomNodeSelectionTable
        isLoading={isLoadingDicomNode}
        selectedGateway={currentSelectedGateway}
        selectedDicomeNodeID={sourceDicomeNodeID}
        onManageNodeCallBack={onManageNodeCallBack}
        onDicomSelectCallBack={onDicomSelectCallBack}
        isShowManageButton={currentSelectedGatewayId}
        data={dicomNode || []}
      />
      {isEnableExcelUpload && (
        <ExcelReader onExcelFileReadFinish={onExcelFileReadFinish} />
      )}

    </div>
  );
};

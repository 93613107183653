import { serverAPIAxios, _generateAuthoriseHeader } from './init'

export const listAllGatewayByUserID = async () => {
  try {
    const response = await serverAPIAxios.get(`/gateway`, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error listAllGatewayByUserID", error);
    throw error;
  }
};

export const listAllAIGatewayByUserID = async () => {
  try {
    const response = await serverAPIAxios.get(`/gateway/ai-gateways`, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error listAllAIGatewayByUserID", error);
    throw error;
  }
};


export const listAllDicomNodeByGatewayID = async (gateway) => {
  try {
    const response = await serverAPIAxios.get(`/dicom-node/${gateway}`, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error listAllDicomNodeByGatewayID", error);
    throw error;
  }
};

export const listAllDicomNodeByGatewayIDForRequestAccess = async (gateway) => {
  try {
    const response = await serverAPIAxios.get(`/dicom-node/request-access/${gateway}`, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error listAllDicomNodeByGatewayIDForRequestAccess", error);
    throw error;
  }
};

export const listAllGatewayByEmail = async (email) => {
  try {
    const response = await serverAPIAxios.get(`/gateway/query/email/${email}`, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error listAllGatewayByEmail", error);
    throw error;
  }
};

export const listAllGatewayByName = async (name) => {
  try {
    const response = await serverAPIAxios.get(`/gateway/query/name/${name}`, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error listAllGatewayByName", error);
    throw error;
  }
};

export const fetchGatewayStatus = async (gateway_id) => {
  try {
    const response = await serverAPIAxios.get(`/gateway/status/${gateway_id}`, {
      timeout: 5000,
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error fetchGatewayStatus", error);
    return {
      data: {
        ip: [],
        status: "offline",
      },
    };
  }
};

export const updateListener = async (gateway_id, data) => {
  try {
    const response = await serverAPIAxios.put(`/listener/${gateway_id}`, data, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error updateListener", error);
    throw error;
  }
};

export const restartListener = async (gateway_id) => {
  try {
    const response = await serverAPIAxios.post(
      `/gateway/restart`,
      {
        gateway_id,
      },
      {
        headers: _generateAuthoriseHeader(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error restartListener", error);
    throw error;
  }
};

export const updateGateway = async (gateway_id, data) => {
  try {
    const response = await serverAPIAxios.put(
      `/gateway`,
      {
        gateway_id,
        ...data,
      },
      {
        headers: _generateAuthoriseHeader(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updateGateway", error);
    throw error;
  }
};

export const deleteGateway = async (gateway_id, data) => {
  try {
    const response = await serverAPIAxios.delete(`/gateway`, {
      data: { gateway_id },
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error deleteGateway", error);
    throw error;
  }
};

export const fetchListenerStatusById = async (gateway_id) => {
  try {
    const response = await serverAPIAxios.get(
      `/listener/status/${gateway_id}`,
      {
        timeout: 5000,
        headers: _generateAuthoriseHeader(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetchListenerStatusById", error);
    return {
      data: {
        ip: [],
        status: "offline",
      },
    };
  }
};

import { useEffect } from "react";
import { message } from "antd";
import { JobStatusList } from "../../components/Jobs/JobStatusList";
import { GatewaySelectionTable } from "../../components/Gateway/Gateway";
import { DicomNodeSelectionTable } from "../../components/DicomNode/DicomNodeSelection";
import { buildQuery } from "../../../apis/node";
import {
  retrieveToServer,
  getJobsDetail,
  retrieveToGateway,
} from "../../../apis/job-status";
import {
  CLOUD_PACS_MODE,
} from "../../constants/dicomNode";
import { AnonymousNameMapStore } from "../../../stores/anonymous";
import AnonymousToggleButton from './AnonymousSetting';
import { DashloardGatewayLoadingStatus } from '../../../stores/gateway'
import { hash } from '../../../libs/crypt'

export const DestinationGateway = ({
  isLoadingGateway,
  selectedStudies,
  selectedSourceGatewayId,
  selectedDestionationGateway,
  selectedSourceDicomNodeId,
  selectedDestinationDicomNodeId,
  onSearchGatewayResultsHandle,
  onGatewaySelect,
  onDicomSelect,
  gatewayData,
  dicomNode,
  addJob,
  updateRunningJobs,
  runningJobs,
}) => {
  const isDestinationGatewayCloudPacsDestinationID = selectedDestionationGateway?.id === CLOUD_PACS_MODE;
  const isShowSendButton =
    selectedStudies &&
    selectedStudies.length !== 0 &&
    (selectedDestinationDicomNodeId > 0 || isDestinationGatewayCloudPacsDestinationID);


  const addOrUpdateJob = async (response) => {
    if (response && response.data && response.data.id) {
      const jobResponse = await getJobsDetail(response.data.id);
      if (jobResponse.data) {
        addJob(jobResponse.data);
      }
    }
  };


  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
    const filteredRunningJobs = runningJobs.filter(
      (job) =>
        job.status !== "job-success" &&
        job.status !== "job-failure" &&
        job.status !== "job_canceled" &&
        job.status !== null
    );

    const updateJobStatus = async () => {
      const updates = [];
      messageApi.open({
        key: "studies-trasnfering",
        type: 'loading',
        content: 'Sending...',
        duration: 0,
      });


      for (let job of filteredRunningJobs) {
        try {
          const jobResponse = await getJobsDetail(job.id);
          const jobStatus = jobResponse.data?.[0]?.status;
          const name = jobResponse.data?.[0]?.name;

          // Collect updates
          if (job.status !== jobStatus) {
            // Only collect updates if there's a change
            updates.push({ id: job.id, status: jobStatus, name });
          }

          if (jobStatus === "job-success") {
            if (selectedDestionationGateway.id !== CLOUD_PACS_MODE) {
              messageApi.open({
                key: "studies-trasnfering",
                type: 'success',
                content: 'Images all uploaded to destination pacs system"',
                duration: 1,
              });
            }
          }
        } catch (error) {
          console.error("Error updating job status:", error);
        }
      }

      // Batch update runningJobs if there are updates
      if (updates.length > 0) {
        updateRunningJobs(updates);
      }
    };

    let intervalId = null;

    if (filteredRunningJobs.length > 0) {
      updateJobStatus();
      intervalId = setInterval(updateJobStatus, 5000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [runningJobs]);

  // this is the hook update toast message when job done
  useEffect(() => {
    const updateJobListSend = () => {
      if (runningJobs && runningJobs.length === 0) {
        return;
      }

      const filteredRunningJobs = runningJobs.filter(
        (job) =>
          job.status !== "job-success" &&
          job.status !== "job-failure" &&
          job.status !== "job_canceled" &&
          job.status !== null
      );

      if (filteredRunningJobs.length === 0) {
        messageApi.open({
          key: 'studies-trasnfering',
          type: 'info',
          content: 'All jobs have completed',
          duration: 3,
        });
      }
    }

    updateJobListSend();
  }, [runningJobs])

  const generateHashFromStudyId = (studyUID, anonymousName) => {
    if (anonymousName) {
      return anonymousName;
    }

    const currentGateway = selectedSourceGatewayId && gatewayData.find((gateway) => gateway.id === selectedSourceGatewayId);
    return `GATEWAY:${currentGateway.name}:${hash(studyUID)}`
  }

  const getAnonymousNameMap = AnonymousNameMapStore((state) => state.getAnonymousNameMap)
  const getAnonymousName = (patientID, accessionNumber) => {
    if (!patientID && !accessionNumber) {
      return null;
    }

    return getAnonymousNameMap(patientID || accessionNumber);
  }

  // if study id not inside map then send without PHI will use this instead
  // only applicaabale for batch transfering
  const generateHash = (studyUID, patientID, accessionNumber) => {
    const anonymousName = getAnonymousName(patientID, accessionNumber);
    if (anonymousName && anonymousName.length > 0) {
      return anonymousName;
    }

    // if study id not inside map then send without PHI will use this instead
    // only applicaabale for batch transfering
    return generateHashFromStudyId(studyUID);
  }

  const onSend = async (sendWithAnonymous) => {
    try {
      if (selectedDestionationGateway.id === CLOUD_PACS_MODE) {
        for (const item of selectedStudies) {
          if (!item.seriesUID || !item.studyUID) {
            continue;
          }
          const response = await retrieveToServer({
            gateway_id: selectedSourceGatewayId,
            node_id: selectedSourceDicomNodeId,
            tags: buildQuery({
              seriesUID: item.seriesUID,
              studyInstanceUID: item.studyUID,
              acquisitionTime: item.acquisitionTime,
              acquisitionNumber: item.acquisitionNumber,
              acquisitionDate: item.acquisitionDate,
              accessionNumber: item.accessionNumber,
              modality: item.modality,
              patientID: item.patientID,
              type: "SERIES",
            }),
            ...(sendWithAnonymous ? {
              anonymous: generateHash(item.studyUID, item.patientID, item.accessionNumber)
            } : {})
          });

          addOrUpdateJob(response);
        }

      } else {
        for (const item of selectedStudies) {
          if (!item.seriesUID || !item.studyUID) {
            continue;
          }
          const response = await retrieveToGateway({
            source_gateway_id: selectedSourceGatewayId,
            source_node_id: selectedSourceDicomNodeId,
            target_gateway_id: selectedDestionationGateway.id,
            target_node_id: selectedDestinationDicomNodeId,
            tags: buildQuery({
              seriesUID: item.seriesUID,
              studyInstanceUID: item.studyUID,
              acquisitionTime: item.acquisitionTime,
              acquisitionNumber: item.acquisitionNumber,
              acquisitionDate: item.acquisitionDate,
              accessionNumber: item.accessionNumber,
              modality: item.modality,
              patientID: item.patientID,
              type: "SERIES",
            }),
            ...(sendWithAnonymous ? {
              anonymous: generateHash(item.studyUID, item.patientID, item.accessionNumber)
            } : {})
          });

          addOrUpdateJob(response);
        }
      }
    } catch (e) {
      message.error(e.response.data.message);
      console.error(e);
    }
  };

  const onGatewayChange = (selectedGateway) => {
    onGatewaySelect(selectedGateway);
  };

  const onDicomSelectChage = (selectDicom) => {
    onDicomSelect(selectDicom);
  }

  const isGatewayDestinationSearching = DashloardGatewayLoadingStatus((state) => state.getGatewayDestinationSearchingStatus)
  return (
    <div className="w-full">
      {contextHolder}
      <div className="flex w-full mb-4 justify-center items-center text-center">
        {isShowSendButton && <AnonymousToggleButton onSend={onSend} node_id={selectedSourceDicomNodeId} />}
      </div>
      <div className="flex mb-5">
        <GatewaySelectionTable
          onGatewayChange={onGatewayChange}
          isLoading={isLoadingGateway || isGatewayDestinationSearching()}
          gatewayData={gatewayData}
          onSearchGatewayResultsHandle={onSearchGatewayResultsHandle}
          selectedGateway={selectedDestionationGateway}
        />

        <DicomNodeSelectionTable
          selectedGateway={selectedDestionationGateway}
          data={dicomNode || []}
          selectedDicomeNodeID={selectedDestinationDicomNodeId}
          onDicomSelectCallBack={onDicomSelectChage}
          isLoading={false}
        />
      </div>

      <JobStatusList selectedSourceGatewayId={selectedSourceGatewayId} data={runningJobs} />

    </div>
  );
};

import { Table } from "antd";
import { useEffect, useState } from "react";
import { findDataSeriesFromNode, buildQuery } from "../../../apis/node";
import { DashboardImageStudiesStore } from "../../../stores/studies";
import { DashboardStudiesKeystore } from "../../../stores/rowsKey";
import { generateRowKey, studyColumns } from "./common";

export const StudiesList = ({
  data,
  onSelect,
  gatewayID,
  nodeID
}) => {
  const [isLoadingSeries, setIsLoadingSeries] = useState(false);
  const setSeriesData = DashboardImageStudiesStore((state) => state.setSeriesData)
  const currentSeriesData = DashboardImageStudiesStore((state) => state.currentSeriesData)

  const fetchAllTheChildren = async (updatedSelectedSeries) => {
    if (!updatedSelectedSeries || updatedSelectedSeries.length === 0) return;
    if (isLoadingSeries) return;
    setIsLoadingSeries(true);
    const updatedMapWithSeries = await Promise.all(updatedSelectedSeries.map(async (record) => {
      const response = await findDataSeriesFromNode(
        gatewayID,
        nodeID,
        buildQuery({
          studyInstanceUID: record.studyInstanceUID,
          type: "SERIES",
        })
      );
      if (response.data && response.data.length > 0) {
        const buildKeyForChildren = response.data.map((series) => {
          return {
            ...series,
            rowKey: generateRowKey(series),
          }
        });

        return {
          ...record,
          children: buildKeyForChildren,
        };
      }
      return record;
    }));

    setSeriesData(updatedMapWithSeries);
    setIsLoadingSeries(false);
  }

  const handleExpand = async (expanded, record) => {
    const currentKey = record.rowKey;
    toggleKeyExpand(currentKey);
  };

  useEffect(() => {
    const loadAllChildren = async () => {
      let updatedSelectedSeries = [];
      if (currentSeriesData.length > 0) {
        return setSeriesData(currentSeriesData);
      }
      
      if (data && currentSeriesData.length === 0) {
        updatedSelectedSeries = data.map((record) => {
          return {
            ...record,
            rowKey: generateRowKey(record),
          };
        });
  
        setSeriesData(updatedSelectedSeries);
        fetchAllTheChildren(updatedSelectedSeries);
      }
    }
    

    loadAllChildren()
  }, [data]);

  const selectedCBKeys = DashboardStudiesKeystore((state) => state.selectedCBKeys)
  const addSelectedCBKey = DashboardStudiesKeystore((state) => state.addSelectedCBKey)
  const deleteSelectedCBKey = DashboardStudiesKeystore((state) => state.deleteSelectedCBKey)
  const toggleKeyExpand = DashboardStudiesKeystore((state) => state.toggleKeyExpand)
  const expandedRowKeys = DashboardStudiesKeystore((state) => state.expandedRowKeys)
  const setStoredStudiesMap = DashboardStudiesKeystore((state) => state.setStoredStudiesMap)
  const getStoredStudiesMap = DashboardStudiesKeystore((state) => state.getStoredStudiesMap)

  const updateSelectStudies = (rowsKeys) => {
    if (rowsKeys.length === 0) {
      onSelect([]);
      return;
    }
    const studies = rowsKeys.map(key => getStoredStudiesMap(key));
    onSelect(studies);
  }

  const addKeyRecursively = (node) => {
    setStoredStudiesMap(node);
    addSelectedCBKey(node.rowKey);
    if (node.children) {
      node.children.forEach((child) => addKeyRecursively(child));
    }
  };

  const removeKeyRecursively = (node) => {
    deleteSelectedCBKey(node.rowKey);
    if (node.children) {
      node.children.forEach((child) => removeKeyRecursively(child));
    }
  };

  const rowSelection = {
    selectedRowKeys: Array.from(selectedCBKeys),
    onSelect: (record, selected, selectedRows) => {
      if (selected) {
        addKeyRecursively(record);
      } else {
        removeKeyRecursively(record);
      }
      updateSelectStudies(Array.from(selectedCBKeys));
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (selected) {
        changeRows.forEach((item) => addKeyRecursively(item));
      } else {
        changeRows.forEach((item) => removeKeyRecursively(item));
      }
      updateSelectStudies(Array.from(selectedCBKeys));
    },
  };

  useEffect(() => {
    updateSelectStudies(Array.from(selectedCBKeys));
  }, [selectedCBKeys])

  return (
    <Table
      className="w-full w-[1300px] border-solid border-[1px]"
      key={`table-${gatewayID}-${nodeID}`}
      pagination={true}
      rowSelection={rowSelection}
      dataSource={currentSeriesData}
      columns={studyColumns}
      rowKey='rowKey'
      expandable={{
        expandedRowKeys: expandedRowKeys,
        onExpand: handleExpand,
      }}
      style={{ width: "100%" }}
    />
  );
};

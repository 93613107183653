import { Layout, Table, Button, Spin, Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  listAllStudiesByUserID,
  listAllSeriesByStudyID,
  deleteSeriesByStudyId,
  downloadStudy,
} from "../../apis/apis";
import {
  downloadSeries,
} from "../../apis/series";
import { FileAddOutlined } from "@ant-design/icons";
import { EditDicomPageModal } from "./EditDicomTag";
import FilterStudies from './Filter'
import { appConfig } from "../../config/config";
import { DashboardCloudPacsStudiesKeystore, DashboardCloudPacsSeriesKeystore } from "../../stores/rowsKey";
import ImportDataModal from "./Uploader/ImportDataModal";

const initSeriesCloudPacsStore = () => {
  const selectedCBKeys = DashboardCloudPacsSeriesKeystore((state) => state.selectedCBKeys)
  const addSelectedCBKey = DashboardCloudPacsSeriesKeystore((state) => state.addSelectedCBKey)
  const deleteSelectedCBKey = DashboardCloudPacsSeriesKeystore((state) => state.deleteSelectedCBKey)
  return {
    selectedCBKeys,
    addSelectedCBKey,
    deleteSelectedCBKey,
  }
}

const initStudiesCloudPacsStore = () => {
  const selectedCBKeys = DashboardCloudPacsStudiesKeystore((state) => state.selectedCBKeys)
  const addSelectedCBKey = DashboardCloudPacsStudiesKeystore((state) => state.addSelectedCBKey)
  const deleteSelectedCBKey = DashboardCloudPacsStudiesKeystore((state) => state.deleteSelectedCBKey)
  return {
    selectedCBKeys,
    addSelectedCBKey,
    deleteSelectedCBKey,
  }
}

const ConfirmationDialog = {
  title: 'Delete confirmation',
  content: (
    <>
      <p>Are you sure want to delete the data?</p>
    </>
  ),
  okButtonProps: {
    style: {
      backgroundColor: '#ff4d4f',
      borderColor: '#ff4d4f',
      color: '#ffffff',
    },
  },
};


export const Studies = () => {
  const [mapStudiesDownloadStudies, setMapStudiesDownloadStudies] = useState(
    {}
  );
  const [modal, contextHolder] = Modal.useModal();

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "patientName",
      key: "patientName",
    },
    {
      title: "Date of Birth",
      dataIndex: "dob",
      key: "dob",
    },
    {
      title: "Study Description",
      dataIndex: "studyDescription",
      key: "studyDescription",
    },
    {
      title: "Patient ID",
      dataIndex: "patientID",
      key: "patientID",
    },
    {
      title: "Accession number",
      dataIndex: "accessionNumber",
      key: "accessionNumber",
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => new Date(record.createdAt).toLocaleString(),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button
          key={record.id}
          onClick={() =>
            window.open(
              `${appConfig.viewerHost}ohif/viewer?StudyInstanceUIDs=${record.studyInstanceUID}`
            )
          }
        >
          View
        </Button>
      ),
    },
    {
      title: "Edit",
      key: "edit",
      render: (text, record) => {
        return <Button
          key={record.id}
          onClick={() => {
            handleOpenEditModel({
              id: record.id,
              parentStudy: record.parentStudy,
              patientName: record.patientDetail_PatientName,
              patientSex: record.patientSex,
              studyDescription: record.studyDescription,
              accessionNumber: record.accessionNumber,
              patientBirthDate: record.patientBirthDate,
              referringPhysicianName: record.referringPhysicianName,
              modality: record.modality,
              studyTime: record.studyTime,
              studyDate: record.studyDate,
            });
          }}
        >
          Edit
        </Button>
      }
    },
    {
      title: "Download",
      key: "download",
      render: (text, record) => {
        if (mapStudiesDownloadStudies[record.parentStudy] === "downloading") {
          return <Spin key={record.id} />;
        }

        return (
          <>
            <Button
              key={record.id}
              onClick={() => {
                downloadStudies(record.id);
              }}
            >
              Download
            </Button>
          </>
        );
      },
    },
  ];
  const [studies, setStudiesData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [expandedData, setExpandedData] = useState({});
  const fetchSeriesWithStudy = async (studies) => {
    try {
      if (!studies || studies.length === 0) {
        return;
      }

      studies.forEach(async (study) => {
        const series = await listAllSeriesByStudyID(study.id);
        if (!series || series.length === 0) {
          setExpandedData((prev) => ({
            ...prev,
            [study.id]: [],
          }));
        } else {
          const seriesData = series.map((seriesItem) => ({
            id: seriesItem.ID,
            seriesDescription: seriesItem.MainDicomTags.SeriesDescription,
            seriesNumber: seriesItem.MainDicomTags.SeriesNumber,
            modality: seriesItem.MainDicomTags.Modality,
            instance: seriesItem.Instances.length || 0,
            studyId: study.id,
          }));

          setExpandedData((prev) => ({
            ...prev,
            [study.id]: seriesData,
          }));
        }
      });

    } catch (e) {
      console.error(e);
    }
  }


  const seriesCloudPacsStore = initSeriesCloudPacsStore()
  const expandedRowRender = ({ id }) => {
    const addKeyRecursively = (node) => {
      seriesCloudPacsStore.addSelectedCBKey(node.id);
    };

    const removeKeyRecursively = (node) => {
      seriesCloudPacsStore.deleteSelectedCBKey(node.id);
    };
    const rowSelection = {
      selectedRowKeys: Array.from(seriesCloudPacsStore.selectedCBKeys || []),
      onSelect: (record, selected, selectedRows) => {
        if (selected) {
          addKeyRecursively(record);
        } else {
          removeKeyRecursively(record);
        }
      },
    };

    const columns = [
      {
        title: 'Series Description',
        dataIndex: 'seriesDescription',
        key: 'seriesDescription',
      },
      {
        title: 'Series Number',
        dataIndex: 'seriesNumber',
        key: 'seriesNumber',
      },
      {
        title: 'Modality',
        dataIndex: 'modality',
        key: 'modality',
      },
      {
        title: 'Instance',
        dataIndex: 'instance',
        key: 'instance',
      },
    ];
    return <Table
      className="series-table"
      rowKey='id'
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }} columns={columns} dataSource={expandedData[id] || []} pagination={false} />;
  };

  const [filterOptions, setFilter] = useState({});
  useEffect(() => {
    const loadFetchStudies = async () => {
      try {
        // if filter options are empty, then we are on the first page
        let currentPage = pagination.current;
        if (filterOptions && Object.keys(filterOptions).length !== 0) {
          currentPage = 1;
        }

        const studiesData = await listAllStudiesByUserID(currentPage, pagination.pageSize, filterOptions);
        if (studiesData.total_items.length === 0) {
          return setStudiesData([]);
        }

        const updatedStudiesDataWithKey = studiesData.data.map((record) => {
          return {
            ...record,
          };
        });

        setStudiesData(updatedStudiesDataWithKey);
        setPagination({
          ...pagination,
          total: studiesData.total_items
        });
        fetchSeriesWithStudy(updatedStudiesDataWithKey);

      } catch (e) {
        console.error(e);
      }
    };

    loadFetchStudies();
  }, [pagination.current, pagination.pageSize, filterOptions]);



  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [currentEditStudyData, setCurrentEditStudyData] = useState({});
  const handleOpenEditModel = (data) => {
    setCurrentEditStudyData({
      ...currentEditStudyData,
      ...data,
    });
    setEditModalOpen(!isEditModalOpen);
  };

  const [isOpen, setOpen] = useState(false);
  const handleOk = () => {
    setOpen(!isOpen);
  };

  const handleOnClose = () => {
    setOpen(!isOpen);
  };

  const [messageApi, messageContextHolder] = message.useMessage();


  const handleDeleteSeriesOrStudy = async () => {
    try {
      const confirmed = await modal.confirm(ConfirmationDialog);
      if (!confirmed) {
        return;
      }

      const selectedSeries = Array.from(seriesCloudPacsStore.selectedCBKeys || []);
      if (!selectedSeries || selectedSeries.length === 0) {
        return;
      }

      messageApi.open({
        key: "delete",
        type: 'loading',
        content: 'Deleting studies...',
        duration: 0,
      });


      await deleteSeriesByStudyId(selectedSeries);

      messageApi.open({
        key: "delete",
        type: 'success',
        content: 'Deleted!',
        duration: 2,
      });

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (e) {
      console.log(e);
    }

  }

  const handleDownloadSeries = async () => {
    try {
      const selectedSeries = Array.from(seriesCloudPacsStore.selectedCBKeys || []);
      if (!selectedSeries || selectedSeries.length === 0) {
        return;
      }
      const fileName = new Date().toISOString();

      messageApi.open({
        key: fileName,
        type: 'loading',
        content: 'Loading...',
        duration: 0,
      });

      const blob = await downloadSeries(fileName, selectedSeries)
      const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `${fileName}.zip`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      setTimeout(() => {
        messageApi.open({
          key: fileName,
          type: 'success',
          content: 'Downloaded!',
          duration: 2,
        });
      }, 1000);
    } catch (e) {
      console.log(e);
    }

  }

  const downloadStudies = async (study) => {
    if (
      mapStudiesDownloadStudies[study] &&
      mapStudiesDownloadStudies[study] === "downloading"
    ) {
      return;
    }

    try {
      setMapStudiesDownloadStudies({
        ...mapStudiesDownloadStudies,
        [study]: "downloading",
      });

      const blob = await downloadStudy(study);
      const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `${study}.zip`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setMapStudiesDownloadStudies({
        ...mapStudiesDownloadStudies,
        [study]: "done",
      });
    } catch (e) {
      console.error("Error in file download", e);
      setMapStudiesDownloadStudies({
        ...mapStudiesDownloadStudies,
        [study]: "error",
      });
    }
  };

  // Event handler for page change
  const handleTableChange = (page, pageSize) => {
    // Update the state with new page and pageSize
    setPagination({
      ...pagination,
      current: page,
      pageSize,
    });
  };


  // Define pagination settings for the inner table
  const innerTablePagination = {
    ...pagination,
    showSizeChanger: true, // Allow changing the page size
    pageSizeOptions: ['10', '20', '50', '100'], // Page size options
    onChange: (page, pageSize) => handleTableChange(page, pageSize),
    onShowSizeChange: (current, size) => handleTableChange(1, size), // Reset to page 1 on size change
  };

  const onChange = (filter) => {
    setFilter(filter)
  }

  const studiesCloudStore = initStudiesCloudPacsStore();
  const addKeyRecursively = (node) => {
    const childItems = expandedData[node.id] || [];
    if (childItems.length > 0) {
      childItems.forEach((item) => {
        seriesCloudPacsStore.addSelectedCBKey(item.id);
      });
    }
    studiesCloudStore.addSelectedCBKey(node.id);
  };

  const removeKeyRecursively = (node) => {
    const childItems = expandedData[node.id] || [];
    if (childItems.length > 0) {
      childItems.forEach((item) => {
        seriesCloudPacsStore.deleteSelectedCBKey(item.id);
      });
    }
    studiesCloudStore.deleteSelectedCBKey(node.id);
  };

  const studyRowSelection = {
    selectedRowKeys: Array.from(studiesCloudStore.selectedCBKeys || []),
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (selected) {
        changeRows.forEach((item) => addKeyRecursively(item));
      } else {
        changeRows.forEach((item) => removeKeyRecursively(item));
      }
    },
    onSelect: (record, selected, selectedRows) => {
      if (selected) {
        addKeyRecursively(record);
      } else {
        removeKeyRecursively(record);
      }
    },
  };

  const shouldDeleteButtonShowUp = studiesCloudStore.selectedCBKeys.size > 0 || seriesCloudPacsStore.selectedCBKeys.size > 0;
  const shouldDownloadButtonShowUp = studiesCloudStore.selectedCBKeys.size > 0 || seriesCloudPacsStore.selectedCBKeys.size > 0;

  return (
    <div>
      <ImportDataModal visible={isOpen} onClose={handleOnClose} />
      <Layout className="bg-white flex-row flex-col w-full">
        <div className="flex justify-between w-full p-2">
          <div className="ml-2.5">
           <FilterStudies onChange={onChange} />
          </div>
          <div className="flex">
            <Button onClick={handleOk} type="primary bg-[#1da1f2]">
              <FileAddOutlined />
              <span>Add</span>
            </Button>
            {shouldDeleteButtonShowUp && <Button onClick={handleDeleteSeriesOrStudy} type="primary ml-4" danger>
              <span>Delete</span>
            </Button>}

            {shouldDownloadButtonShowUp && <Button onClick={handleDownloadSeries} type="primary ml-4" danger>
              <span>Download</span>
            </Button>}

          </div>
        </div>
        <Table
          expandable={{ expandedRowRender }}
          pagination={innerTablePagination}
          rowKey="id"
          columns={columns}
          dataSource={studies}
          rowSelection={{
            type: 'checkbox',
            ...studyRowSelection,
          }}
        />
      </Layout>
      {isEditModalOpen && (
        <EditDicomPageModal
          currentEditStudyData={currentEditStudyData}
          handleCancel={handleOpenEditModel}
          isOpen={isEditModalOpen}
        />
      )}
      {contextHolder}
      {messageContextHolder}
    </div>
  );
};

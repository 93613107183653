import { Table, Button, Typography, Input, Popconfirm, Modal } from "antd";
import { ManageJobs } from "./ManageJob";
import { useState } from "react";
import moment from "moment";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from '@ant-design/icons';
import { DashboardJobsKeyStore } from "../../../stores/rowsKey";
import { cancelRunningJobs, getJobsDetail } from '../../../apis/job-status';
import { message, Tooltip } from 'antd';
const { Title } = Typography;

export const JobStatusList = ({ data, selectedSourceGatewayId }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const formattedData = data
    .map(item => ({
      ...item,
      createdAt: item.createdAt ? moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss") : "N/A",
    }))
    .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)));

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          className="mr-2 bg-blue-500"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => {
          handleReset(clearFilters);
          handleSearch('', confirm, dataIndex)
          setSearchedColumn('')
        }} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    render: text =>
      searchedColumn === dataIndex ? (
        <div className="w-[90px]">
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text && text.length > 0 ? text.toString() : ''}
          />
        </div>

      ) : (
        <Tooltip className="w-[110px] truncate" placement="topLeft" title={text}>
          <p>{text}</p>
        </Tooltip>
      ),
  });

  const [messageApi, messageContextHolder] = message.useMessage();
  const [logModalVisible, setLogModalVisible] = useState(false);
  const [currentLog, setCurrentLog] = useState(null);
  const onViewJobDetailLog = async (jobId) => {
    try {
      if (!jobId) {
        return;
      }
      const jobResponse = await getJobsDetail(jobId);
      const msg = jobResponse.data?.[0]?.msg || 'No logs found';
      setCurrentLog(msg);
      setLogModalVisible(true);
    } catch (error) {
      console.error(error);
    }
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      ...getColumnSearchProps('name'),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        return <p>{record.status || "Waiting"}</p>;
      },
    },
    {
      title: "Logs",
      dataIndex: "status",
      render: (text, record) => {
        return (
          <Button
            className="bg-blue-500 hover:bg-blue-700"
            type="primary"
            onClick={() => {
              onViewJobDetailLog(record.id)
            }}
          >
            View Logs
          </Button>
        )

      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      className: "w-[50px]",
      render: (text, record) => {
        return <p>{record.createdAt}</p>;
      },
    },
  ];

  const selectedCBKeys = DashboardJobsKeyStore((state) => state.selectedCBKeys)
  const addSelectedCBKey = DashboardJobsKeyStore((state) => state.addSelectedCBKey)
  const deleteSelectedCBKey = DashboardJobsKeyStore((state) => state.deleteSelectedCBKey)
  const clearAllKeys = DashboardJobsKeyStore((state) => state.clearAllCBKeys)

  const addKeyRecursively = (node) => {
    addSelectedCBKey(node.id);
    if (node.children) {
      node.children.forEach((child) => addKeyRecursively(child));
    }
  };

  const removeKeyRecursively = (node) => {
    deleteSelectedCBKey(node.id);
    if (node.children) {
      node.children.forEach((child) => removeKeyRecursively(child));
    }
  };

  const selectedRowKeys = Array.from(selectedCBKeys);
  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, selected, selectedRows) => {
      if (selected) {
        addKeyRecursively(record);
      } else {
        removeKeyRecursively(record);
      }
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (selected) {
        changeRows.forEach((item) => addKeyRecursively(item));
      } else {
        changeRows.forEach((item) => removeKeyRecursively(item));
      }
    },
  };

  const onCancelJobs = async (ids) => {
    if (ids.length === 0) {
      return;
    }

    if (!selectedSourceGatewayId) {
      return;
    }

    try {
      messageApi.open({
        content: 'Cancelling jobs...',
        duration: 0,
        type: 'loading',
        key: 'canceling-jobs'
      })
  
      const cancelPromises = ids.map(jobId => {
        return cancelRunningJobs(selectedSourceGatewayId, jobId);
      });
  
      await Promise.all(cancelPromises);
  
      clearAllKeys();
  
      messageApi.open({
        content: 'Jobs cancelled',
        duration: 2,
        type: 'success',
        key: 'canceling-jobs'
      })
    } catch (error) { 
      console.error(error);
      messageApi.open({
        content: 'Failed to cancel jobs',
        duration: 2,
        type: 'error',
        key: 'canceling-jobs'
      })
    }
  }

  const [isManageJobsOpen, setManageJobOpen] = useState(false);

  return (
    <div className="w-[1265px] flex-col text-center">
      {isManageJobsOpen && (
        <ManageJobs
          onCancelJobs={(ids) => onCancelJobs(ids)}
          handleClose={() => {
            setManageJobOpen(!isManageJobsOpen);
          }}
          isOpen={isManageJobsOpen}
        />
      )}
      <div className="flex-col">
        <div className="flex flex-row text-center pb-[10px] justify-center items-center text-center">
          <Title className="text-center" level={5}>
            Running Jobs
          </Title>
          <div>
            <Button
              className="ml-5 bg-blue-500"
              type="primary"
              onClick={() => {
                setManageJobOpen(!isManageJobsOpen);
              }}
            >
              Manage
            </Button>
            <Popconfirm
              title="Are you sure you want to cancel all jobs?"
              onConfirm={() => onCancelJobs(selectedRowKeys)}
              okText="Yes"
              cancelText="No"
              okButtonProps={{ className: "bg-blue-500 hover:bg-blue-600" }}
            >
              <Button disabled={!(selectedRowKeys && selectedRowKeys.length > 0)}
                danger className="ml-2">
                Cancel
              </Button>
            </Popconfirm>
          </div>
        </div>
      </div>

      <Table
        className="border-solid border-t-[1px] border-l-[1px] border-r-[1px]"
        columns={columns}
        rowKey="id"
        rowSelection={rowSelection}
        dataSource={formattedData}
        pagination={{ pageSize: 5 }}
        scroll={{ y: 300 }}
      />

      {logModalVisible && <Modal
        title="Job Logs"
        visible={logModalVisible}
        onCancel={() => setLogModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setLogModalVisible(false)}>
            Close
          </Button>,
        ]}
      >
        <p>{currentLog}</p>
      </Modal>}
      {messageContextHolder}
    </div>
  );
};

import { Typography, Radio, Table, Button, Spin, message } from "antd";
import { cancelRunningJobByGatewayId } from '../../../apis/job-status'
import { MODAL_EDIT_GATEWAY } from '../../../../src/pages/constants/modals';
import { useContext } from "react";
import { AppContext } from '../../../libs/context';
import { SearchFilter } from "../../components/Filters/Filters";
import { DashloardGatewayLoadingStatus } from '../../../stores/gateway'
import {
  listAllGatewayByEmail,
} from "../../../apis/gateway";
const { Title } = Typography;

export const GatewaySelectionTable = ({
  gatewayData = [],
  isLoading = false,
  onGatewayChange,
  selectedGateway,
  allowShowEditGateway,
  onSearchGatewayResultsHandle,
  showKillJob,
}) => {
  const columns = [
    {
      title: "",
      key: "gateway_selection",
      width: "15px",
      className: "border-solid border-r-[1px]",
      render: (text, record) => (
        <Radio
          data-testid={`gateway-${record.listener_ip}`}
          checked={selectedGateway?.id ? record.id === selectedGateway.id : false}          onChange={() => handleRadioChange(record)}
        />
      ),
    },
    {
      title: "Gateway Name",
      dataIndex: "name",
      className: "text-xs",
      render: (_, record) => {
        return <p className="max-w-[90px]">{record.name}</p>;
      },
    },
    {
      title: "Gateway Status",
      dataIndex: "status",
      className: "w-[100px] text-xs",
    },
    {
      title: "Listener IP",
      dataIndex: "listener_ip",
      className: "text-xs",
      render: (_, record) => {
        return <p className="max-w-[150px]">{record.listener_ip}</p>;
      },
    },
    {
      title: "Listener Status",
      dataIndex: "listener_status",
      className: "w-[90px] text-xs",
    },
    {
      title: "Type",
      dataIndex: "type",
      className: "w-[100px] text-xs",
      render: (_, record) => {
        return <p>{record.type || 'N/A'}</p>;
      },
    },
  ];

  const handleRadioChange = (record) => {
    onGatewayChange(record);
  };

  const filteredAdminEditGatewayOnly = gatewayData.filter((gateway) => {
    return gateway.type === "admin";
  });

  const onKillAllJobClick = async () => {
    message.info('Clearing all jobs...', 2);
    try {
      await cancelRunningJobByGatewayId(selectedGateway.id);
      message.info('All Jobs Cancelled', 2);
    } catch (error) {
      console.error(error);
      message.error(error.message);
    }
  }


  const allowToKillJob = selectedGateway && gatewayData && gatewayData.filter((gateway) => {
    return gateway.type === "admin" && gateway.id === selectedGateway.id && gateway.status.indexOf("online") >= 0 && gateway.id !== 'cloud-pacs';
  })

  const { setCurrentModal } = useContext(AppContext);
  const onEditGatewayModalClick = () => {
    setCurrentModal({
      modal: MODAL_EDIT_GATEWAY,
      data: filteredAdminEditGatewayOnly,
    });
  }

  const getGatewayDestinationSearchingStatus = DashloardGatewayLoadingStatus((state) => state.getGatewayDestinationSearchingStatus)
  const setGatewayDestinationSearching = DashloardGatewayLoadingStatus((state) => state.setGatewayDestinationSearching)
  const isSearching = getGatewayDestinationSearchingStatus();
  const onSearchGateway = async (value) => {
    if (!value || value.trim().length === 0) {
      onSearchGatewayResultsHandle([]);
      return;
    }

    if (value && value.trim().length > 0) {
      if (isSearching) return;
      setGatewayDestinationSearching(true);
      try {
        const response = await listAllGatewayByEmail(value);

        if (response.data) {
          onSearchGatewayResultsHandle(response.data);
        }
      } catch (e) {
        message.error(e.message);
        console.error(e);
      } finally {
        setGatewayDestinationSearching(false);
      }
    }
  }

  return (
    <Spin spinning={isLoading || isSearching}>
      <div className="flex-col text-center w-[615px]">
        <div className="flex flex-row justify-center items-center text-center">
          <div className="flex flex-row">
            <Title level={5}>
              Gateway
            </Title>
            {onSearchGatewayResultsHandle && <div className="flex">
              <SearchFilter
                onSearchClick={(value) => onSearchGateway(value)}
              />
            </div>}
            {allowShowEditGateway && filteredAdminEditGatewayOnly.length !== 0 && gatewayData && gatewayData.length !== 0 && (
              <Button
                className="ml-5"
                data-testid={`edit-gateway-button`}
                onClick={onEditGatewayModalClick}
              >
                Edit
              </Button>
            )}

            {showKillJob && allowToKillJob && allowToKillJob.length !== 0 && <Button
              className="ml-2"
              data-testid={`edit-gateway-button`}
              onClick={onKillAllJobClick}
              danger
            >
              Clear All Jobs
            </Button>}
          </div>
        </div>
        <Table
          className="border-r-[1px] border-t-[1px] border-l-[1px]"
          columns={columns}
          dataSource={gatewayData}
          rowKey={(record) => `${record.id}-${record.listener_ip}-gateway`}
          pagination={false}
        />
      </div>
    </Spin>
  );
};

import { Layout, Divider, message } from "antd";
import { StudiesList } from "../components/Studies/StudiesList";
import SearchFilter from "./Filter"
import { findDataFromNode, buildQuery } from "../../apis/node";
import { useState, useEffect } from "react";
import { DestinationGateway } from "../components/GatewayDicom/DestinationGatewayFilter";
import { SourceGatewayDicomNodeSelection } from "../components/GatewayDicom/SourceGatewayFilter";
import { fetchGatewaysStatus } from '../components/GatewayDicom/hooks/GatewayStatusCustomHook';
import { fetchDicomNodes } from '../components/GatewayDicom/hooks/DicomNodeCustomHook';
import { DashboardGatewayStore } from '../../stores/gateway';
import { DashboardDicomNodeStore } from '../../stores/dicom-node';
import { DashboardStudiesKeystore } from '../../stores/rowsKey';
import { DashboardImageStudiesStore } from '../../stores/studies';
import { formatLayout } from '../../libs/date'
import lodash from 'lodash';
import {
  listAllGatewayByUserID,
} from "../../apis/gateway";
import {
  CLOUD_PACS_MODE,
  GATEWAY_ONLINE_STATUS,
} from "../constants/dicomNode";
import { DashboardJobStore } from '../../stores/jobs'
import _ from "lodash";
import styled from 'styled-components';

const CloudPacs = {
  id: CLOUD_PACS_MODE,
  name: "Cloud PACS System",
  status: GATEWAY_ONLINE_STATUS,
  listener_ip: "N/A",
  listener_status: "N/A",
  gateway_ip: "N/A",
};

const Container = styled.div`
  width: 100%;
  display: flex;
  border-style: solid;
  border-width: 1px;
  padding-top: 12px;
  padding-bottom: 12px;
`;

export const Dashboard = () => {
  const [isFromSearchLoading, setFromSearchLoading] = useState(false);
  const selectedSourceGatewayId = DashboardGatewayStore((state) => state.sourceGatewayId)
  const setSourceGatewayId = DashboardGatewayStore((state) => state.setSourceGatewayId)
  const sourceDicomeNodeID = DashboardDicomNodeStore((state) => state.sourceDicomeNodeID)
  const setSourceDicomeNodeID = DashboardDicomNodeStore((state) => state.setSourceDicomeNodeID)
  const setSeriesData = DashboardImageStudiesStore((state) => state.setSeriesData)
  const setSelectedListStudies = DashboardImageStudiesStore((state) => state.setSelectedSeries)
  const currentStudyData = DashboardImageStudiesStore((state) => state.currentImageSharingStudyData)
  const setStudyData = DashboardImageStudiesStore((state) => state.setImageSharingStudyData)
  const clearAllCBKeys = DashboardStudiesKeystore((state) => state.clearAllCBKeys)

  const setDestinationGateways = DashboardGatewayStore((state) => state.setDestinationGateways)
  const destinationGateways = DashboardGatewayStore((state) => state.destinationGateways)
  const searchStudies = async ({
    patientName,
    patientID,
    accessionNumber,
    studyDescription,
    referringPhysicianName,
    modality,
    birthday,
    studyDate,
  }) => {
    if (
      lodash.isEmpty(patientName) &&
      lodash.isEmpty(patientID) &&
      lodash.isEmpty(accessionNumber) &&
      lodash.isEmpty(studyDescription) &&
      lodash.isEmpty(referringPhysicianName) &&
      lodash.isEmpty(birthday)
    ) {
      return; // Don't do anything if all fields are empty, undefined, or have a string length of 0
    }

    if (isFromSearchLoading) return;
    setFromSearchLoading(true);
    setStudyData([]);
    setSeriesData([]);
    setSelectedListStudies([]);
    clearAllCBKeys();
    try {
      const query = {
        patientName,
        patientID,
        accessionNumber,
        studyDescription,
        referringPhysicianName,
        modality,
        birthday,
        studyDate,
      };

      if (!lodash.isEmpty(studyDate) || !lodash.isEmpty(modality)) {
        query.type = 'SERIES';
      }

      const tagsFilter = buildQuery(query);

      const response = await findDataFromNode(
        selectedSourceGatewayId,
        sourceDicomeNodeID,
        tagsFilter
      );

      if (response.data) {
        const filteredGroupStudy = response.data.reduce((acc, data) => {
          const foundData = acc.filter(
            (item) => item.studyInstanceUID === data.studyInstanceUID
          );
          if (foundData.length === 0) {
            return [...acc, data];
          }

          return acc;
        }, []);

        setStudyData([
          ...filteredGroupStudy,
        ]);
      }
    } catch (e) {
      message.info('No result found');
      console.error(e);
    } finally {
      setFromSearchLoading(false);
    }
  };

  const currentSelectedListStudies = DashboardImageStudiesStore((state) => state.currentSelectedSeries)
  const onSelectStudyRecord = (records) => {
    setSelectedListStudies(records);
  };

  const onDicomNodeSelected = (nodeId) => {
    setSourceDicomeNodeID(nodeId);
  };

  const gatewayData = DashboardGatewayStore((state) => state.gatewayData)
  const setGatewayData = DashboardGatewayStore((state) => state.setGatewayData)
  const [isLoadingGateway, setLoadingGateway] = useState(false);
  const onSourceGatewaySelect = async (gatewayId) => {
    if (gatewayId !== selectedSourceGatewayId) {
      setDicomNodes([]);
    }
    setSourceGatewayId(gatewayId);
    const result = await refetchCurrentGatewayStatus(gatewayData);
    setGatewayData(result);
  };


  const fetchGatewayAndStatus = async () => {
    try {
      setLoadingGateway(true);
      const response = await listAllGatewayByUserID();
      if (!response.data) return [];

      const gatewayWithStatus = await fetchGatewaysStatus(response.data);
      return gatewayWithStatus;
    } catch (e) {
      console.error(e);
      return [];
    } finally {
      setLoadingGateway(false);
    }
  }

  const fetchSourceGateways = async () => {
    const gateways = await fetchGatewayAndStatus();
    if (gateways && gateways.length === 0) {
      return;
    }
    setGatewayData(gateways);
    setSourceGatewayId(gateways[0].id);
  }

  const fetchDestinationGateways = async () => {
    const gateways = await fetchGatewayAndStatus();
    if (gateways && gateways.length === 0) {
      return;
    }
    setDestinationGateways(gateways);
    setDestionationSelectedSourceGateway(CloudPacs);
  }

  const refetchCurrentGatewayStatus = async (gateways) => {
    try {
      if (!gateways || gateways.length === 0) return;
      const gatewayWithStatus = await fetchGatewaysStatus(gateways);
      return gatewayWithStatus;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  useEffect(() => {
    if (!gatewayData || gatewayData.length === 0) {
      // first init the app
      fetchSourceGateways();
      fetchDestinationGateways();
    } else {
      setGatewayData(gatewayData);
    }
  }, [])

  const currentSelectedGateway = gatewayData && gatewayData.find((gateway) => gateway.id === selectedSourceGatewayId);
  const storedDicomNodes = DashboardDicomNodeStore((state) => state.sourceDicomNodes)
  const setDicomNodes = DashboardDicomNodeStore((state) => state.setSourceDicomNodes)
  const [isLoadingDicomNode, setLoadingDicomNode] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    const fetchDicomNode = async () => {
      if (isLoadingDicomNode) return;
      if (!currentSelectedGateway || currentSelectedGateway.length === 0) return;
      try {
        setLoadingDicomNode(true)
        const data = await fetchDicomNodes({ currentSelectedGateway });
        setDicomNodes(data);
      } catch (e) {
        messageApi.open({
          key: `gateway-dicome-listing-${currentSelectedGateway.id}`,
          type: 'error',
          content: e.message || 'failed to fetch dicom node',
        });
      } finally {
        setLoadingDicomNode(false);
      }
    }

    if (!storedDicomNodes || storedDicomNodes.length === 0) {
      fetchDicomNode();
    }
  }, [currentSelectedGateway])


  const setDestionationSelectedSourceGateway = DashboardGatewayStore((state) => state.setSelectedDestinationGateway)
  const selectedDestionationSourceGateway = DashboardGatewayStore((state) => state.selectedDestinationGateway)
  const setDestinationDicomNodes = DashboardDicomNodeStore((state) => state.setDestinationDicomNodes)
  const destinationDicomNodes = DashboardDicomNodeStore((state) => state.destinationDicomNodes)

  const selectedDestinationDicomNodeId = DashboardDicomNodeStore((state) => state.destinationDicome)
  const setSelectedDestinationDicomeNode = DashboardDicomNodeStore((state) => state.setDestinationDicome)
  const onDestinationGatewayChange = (gateway) => {
    if (gateway != selectedDestionationSourceGateway) {
      // gateway select is different clear;
      setDestinationDicomNodes([]);
    }
    setDestionationSelectedSourceGateway(gateway)
  }
  const onDestinationDicomNodeChange = (node) => {
    setSelectedDestinationDicomeNode(node);
  }

  const currentSelectedDestinationGateway = destinationGateways && destinationGateways.find((gateway) => gateway.id === selectedDestionationSourceGateway.id);
  useEffect(() => {
    const fetchDicomNode = async () => {
      if (!currentSelectedDestinationGateway || !currentSelectedDestinationGateway.id) return;
      try {
        const data = await fetchDicomNodes({ currentSelectedGateway: currentSelectedDestinationGateway });
        setDestinationDicomNodes(data);
      } catch (e) {
        messageApi.open({
          key: `gateway-dicome-listing-${currentSelectedDestinationGateway.id}`,
          type: 'error',
          content: e.message || 'failed to fetch dicom node',
        });
      }
    }

    if (!destinationDicomNodes || destinationDicomNodes.length === 0) {
      fetchDicomNode();
    }
  }, [selectedDestionationSourceGateway])


  const onSearchForSourceGateWay = async (gateways) => {
    setGatewayData([]);
    setDicomNodes(null);

    if (gateways && gateways.length > 0) {
      const result = await refetchCurrentGatewayStatus(gateways);
      setGatewayData(result);
    } else {
      fetchSourceGateways();
    }
  }

  const onSearchForDestinationGateWay = async (gateways) => {
    setDestinationGateways([]);
    setSelectedDestinationDicomeNode(null);
    setDestinationDicomNodes([]);
    setDestionationSelectedSourceGateway(CloudPacs)
    //
    if (gateways && gateways.length > 0) {
      const result = await refetchCurrentGatewayStatus(gateways);
      setDestinationGateways(result);
    } else {
      fetchDestinationGateways();
    }
  }

  const addJob = DashboardJobStore((state) => state.addJob)
  const updateRunningJobs = DashboardJobStore((state) => state.updateRunningJobs)
  const runningJobs = DashboardJobStore((state) => state.runningJobs)

  const onSearchClick = (filters) => {
    let studyDateRange = null;
    let birthday = null;
    if (filters.studyDateRange) {
      studyDateRange = filters.studyDateRange.map((date) => formatLayout(date));
    }

    if (filters.birthday) {
      birthday = formatLayout(filters.birthday);
    }

    searchStudies({
      ...filters,
      birthday,
      studyDate: filters.studyDate ? `${studyDateRange[0]}-${studyDateRange[1]}` : null,
    })
  }
  return (
    <Layout className="bg-white flex-row flex-col w-full">
      {contextHolder}
      <div className="p-4 mx-0 center mx-auto">
        <h1 className="text-2xl font-bold text-center mb-4">Image Transfer</h1>
        <Divider />
        <div className="mb-4 max-w-[1000px]">
          <SearchFilter label='From :' isLoading={isFromSearchLoading} onChange={onSearchClick} />
        </div>
        <Container>
          <SourceGatewayDicomNodeSelection
            isLoadingGateway={isLoadingGateway}
            isLoadingDicomNode={isLoadingDicomNode}
            gatewayData={gatewayData}
            dicomNode={storedDicomNodes}
            sourceDicomeNodeID={sourceDicomeNodeID}
            currentSelectedGatewayId={selectedSourceGatewayId}
            currentSelectedGateway={currentSelectedGateway}
            onGatewaySelect={onSourceGatewaySelect}
            onDicomNodeSelected={onDicomNodeSelected}
            onSearchGatewayResultsHandle={onSearchForSourceGateWay}
          />
        </Container>
        <div className="w-full py-3">
          <StudiesList
            nodeID={sourceDicomeNodeID}
            gatewayID={selectedSourceGatewayId}
            onSelect={onSelectStudyRecord}
            data={currentStudyData}
          />
        </div>
        <Divider />
        <p className='text-xl font-bold mb-3'>To :</p>
        <Container>
          <DestinationGateway
            isLoadingGateway={isLoadingGateway}
            selectedSourceGatewayId={selectedSourceGatewayId}
            selectedSourceDicomNodeId={sourceDicomeNodeID}
            selectedDestionationGateway={selectedDestionationSourceGateway}
            selectedDestinationDicomNodeId={selectedDestinationDicomNodeId}
            selectedStudies={currentSelectedListStudies}
            onDicomSelect={onDestinationDicomNodeChange}
            onGatewaySelect={onDestinationGatewayChange}
            onSearchGatewayResultsHandle={onSearchForDestinationGateWay}
            dicomNode={destinationDicomNodes}
            runningJobs={runningJobs}
            addJob={addJob}
            updateRunningJobs={updateRunningJobs}
            gatewayData={[CloudPacs, ...destinationGateways]}
          />
        </Container>

      </div>
    </Layout>
  );
};

import { useEffect, useState } from "react";
import { Typography, Button, Radio, Table, Spin } from "antd";
const Title = Typography.Title;

export const DicomNodeSelectionTable = ({
  isShowManageButton,
  onManageNodeCallBack,
  onDicomSelectCallBack,
  data,
  isLoading = false,
  selectedGateway,
  selectedDicomeNodeID
}) => {
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  useEffect(() => {
    if (selectedDicomeNodeID && selectedDicomeNodeID > 0) {
      setSelectedRowKey(selectedDicomeNodeID);
      handleRadioChange(selectedDicomeNodeID);
    } else {
      if (data && data.length > 0) {
        setSelectedRowKey(data[0].id);
        handleRadioChange(data[0].id)
      }
    }
  }, [data]);

  const columns = [
    {
      title: "",
      key: "dicome_node_selection",
      className: "border-solid border-r-[1px]",
      render: (text, record) => {
        return (
          <Radio
            key={record.id}
            data-testid={`dicome-node-${record.id}`}
            checked={selectedRowKey === record.id}
            onChange={() => {
              handleRadioChange(record.id);
            }}
          />
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      className: "text-xs",
      render: (_, record) => {
        return <p>{record.name}</p>;
      },
    },
    {
      title: "AET",
      dataIndex: "aet",
      className: "text-xs",
    },
    {
      title: "Address",
      dataIndex: "ip",
      className: "text-xs",
      render: (_, record) => {
        return (
          <>
            {`${record.ip}`}
            <br></br>
            {record.port}
          </>
        );
      },
    },
    {
      title: "Accessibilities",
      dataIndex: "accessibilities",
      className: "text-xs",
      render: (_, record) => {
        if (!record.accessibilities || record.accessibilities.length === 0) {
          return <p>N/A</p>
        }
        const accessibilityNames = record.accessibilities.map((accessibility) => accessibility.name).join(", ");
        return <p>{accessibilityNames}</p>;
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      className: "text-xs",
    },
  ];

  const handleRadioChange = (id) => {
    setSelectedRowKey(id);
    onDicomSelectCallBack(id);
  };

  return (
    <Spin spinning={isLoading}>
      <div className="flex-col text-center w-[630px] ml-5">
        <div className="flex flex-row justify-center items-center text-center pb-[10px]">
          <Title level={5}>
            DICOM Node
          </Title>
          {selectedGateway &&
            selectedGateway.type === "admin" &&
            isShowManageButton && (
              <Button onClick={onManageNodeCallBack} className="ml-5">
                Manage
              </Button>
            )}
        </div>
        <Table
          className="border-solid border-r-[1px] border-t-[1px] border-l-[1px] w-full"
          columns={columns}
          dataSource={data}
          rowKey="id"
          pagination={false}
        />
      </div>
    </Spin>
  );
};
